if (process.env.NODE_ENV === 'development') {
    // Must use require here as import statements are only allowed
    // to exist at the top of a file.
    require('preact/debug');
}

import { h, render } from 'preact';
import { ThemeProvider } from "@material-tailwind/react";
import { HomePageLayout } from './page/home.page';

const appRoot = document.getElementById('app');

const App = (
 <ThemeProvider>
   <HomePageLayout></HomePageLayout>
 </ThemeProvider>
)

render(App, appRoot);
