import { h, render } from 'preact';
import { Carousel } from '@material-tailwind/react';
import blackLongSleeveImg from '../../images/longsleeve_blk.jpg'
import grayLongSleeveImg from '../../images/longsleeve_gray.jpg'
import redTshirtLogo from '../../images/tshirt_redlogo.jpg';
import blueSweatshirt from '../../images/sweatshirt_blue.jpg';
import blueHoodie from '../../images/blue_hoody.jpg';
import blueTshirt from '../../images/tshirt_blue.jpg';
import blueJacket from '../../images/bluejacket.jpg';
import underSweatshirt from '../../images/under.jpg';
import navyKnit from '../../images/navyknit.jpg';

export function HomeCarousel() {
  const carouselImages = [
    {
      src:redTshirtLogo,
      altText: 'gray logo long sleeve t-shirt'
    },
    {
      src:blueSweatshirt,
      altText: 'black logo long sleeve t-shirt'
    },
    {
      src:blackLongSleeveImg,
      altText: 'red logo t-shirt'
    },
    {
      src:blueHoodie,
      altText: 'blue logo hooded sweatshirt'
    },
    {
      src:grayLongSleeveImg,
      altText: 'blue logo sweatshirt'
    },
    {
      src:blueTshirt,
      altText: 'blue logo t-shirt'
    },
    {
      src:underSweatshirt,
      altText: 'royal tshirt under view'
    },
    {
      src:blueJacket,
      altText: 'blue logo windbreaker'
    },
    {
      src:navyKnit,
      altText: 'navy logo knit'
    },
  ]

  return (
    <Carousel className="bg-black max-h-[600px] mb-8" autoplay={true} loop={true}>
      {
        carouselImages.map(image => {
          return (
            <img
              src={image.src}
              alt={image.altText}
              className="mx-auto"
            />
          )
        })
      }
    </Carousel>
  )
}
