import { h, render, Fragment } from 'preact';
import { HomeCarousel } from '../components/homeCarousel.component';

export function HomePageLayout() {
  return (
    <div class="container mx-auto">
      <div class="w-full lg:w-[800px] mx-auto">
        <HomeCarousel></HomeCarousel>

        <div className={'px-4'}>
          <h2 className={'text-center text-3xl font-bold'}>REAL Clothing <br className={'sm:hidden'}/>for REAL people. <br/>Coming Soon</h2>
        </div>
      </div>
    </div>
)
  ;
}
